<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-img style="margin-left: 25%" class="img-fluid  mb-1"
              :src="appLogoImage"
              alt="logo"
              width="120"
              height="100"
          />
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1"
          >
            Password Resetting
          </b-card-title>
          <b-card-text class="mb-2">

          </b-card-text>

          <!-- form -->
          <validation-observer ref="PasswordResetValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Enter Registered Phone Number"
                label-for="registered_phone_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="registered_phone_number"
                    v-model="phoneNumber"
                    :state="errors.length > 0 ? false:null"
                    name="registered_phone_number"
                    placeholder="Mobile Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="SubmitForm"
              >
                Send Code
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol,  BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import apiCall from "@/libs/axios";
import {getHomeRouteForLoggedInUser, isUserLoggedIn} from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      phoneNumber: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogoImage: require('@/assets/images/logo/logo.png'),
      required,
      email
    }
  },
  created() {
    if (isUserLoggedIn()) this.$router.replace(getHomeRouteForLoggedInUser(this.$store.getters.user.role));
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {

    SubmitForm() {
      this.$refs.PasswordResetValidation.validate().then(success => {
        if (success) {
          let formData = new FormData();
         
          formData.append('registered_phone_number', this.phoneNumber);
          
          apiCall.post('/send/password/reset/code', formData)
              .then((response) => {

                if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.$router.push({ name: 'reset-password-by-user', params: { phone: this.phoneNumber } })
                }
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'warning',
                    text: 'Invalid request',
                  },
                })
              })
          }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
